export default [
  {
    prop: 'id',
    label: 'ID',
    type: 'text'
  },
  {
    prop: 'name',
    label: 'Tên',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'address',
    label: 'Địa chỉ',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'phone',
    label: 'Số điện thoại',
    minWidth: 150,
    type: 'text'
  }
]