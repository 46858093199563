<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="locations" :actions="actions" />
        </div>
      </div>
    </div>
    <toast position="se"></toast>
  </div>
</template>
<script>
  import { MessageBox, Message } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import locationSchemas from './location-schemas'
  import dtHelper from 'src/helpers/datatable'
  import { Toast } from 'vuex-toast'
  import { mapActions } from 'vuex'
  import { ADD_TOAST_MESSAGE } from 'vuex-toast'

  export default{
    components: {
      MyTable,
      Toast,
      MyFilter
    },
    computed: {
      locations() {
        let rows = this.$store.state.locations;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data () {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(locationSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.remove
          }
        ],
        filter: {},
        rules: dtHelper.buildRules(locationSchemas)
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchLocations');
      this.$store.dispatch('setPageTitle', 'Quản lý CLB');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Tạo mới',
        type: 'primary',
        icon: '',
        callback: this.createLocation
      }])
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },
      edit(index, row){
        this.$router.push('/locations/' + row.id);
      },
      createLocation() {
        this.$router.push('/locations/create');
      },
      ...mapActions({
        addToast: ADD_TOAST_MESSAGE
      }),
      toast(text, type) {
        this.addToast({
          text,
          type: type,
          dismissAfter: 3000
        })
      },
      remove(index, row) {
        MessageBox.confirm('Xóa địa điểm?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.$store.dispatch('removeLocation', row.id).then(res => {
            if (res.success) {
              this.toast('Đã xóa thành công', 'success');
              this.$store.dispatch('fetchLocations');
            } else {
              if (res.errors) {
                this.toast(res.errors, "danger");
              } else {
                this.toast("Có lỗi xảy ra, xin vui lòng thử lại", "danger");
              }
            }
          });
        }, error => {

        });
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
